import ApiActions from "api_actions";

import { DEFAULT_CURRENCY } from "constants/defaults";
import moment from "moment";

export const SET_CHANNEL_ID = "SET_CHANNEL_ID";
export const SET_PROPERTY_LOADING = "SET_PROPERTY_LOADING";
export const SET_PROPERTY_DATA = "SET_PROPERTY_DATA";
export const SET_ROOMS_LOADING = "SET_ROOMS_LOADING";
export const SET_ROOMS_DATA = "SET_ROOMS_DATA";
export const SET_FILTER_ROOMS_DATA = "SET_FILTER_ROOMS_DATA";
export const SET_CLOSED_DATES_LOADING = "SET_CLOSED_DATES_LOADING";
export const SET_CLOSED_DATES_DATA = "SET_CLOSED_DATES_DATA";
export const SET_PARAMS = "SET_PARAMS";
export const SET_ROOMS_REQUEST_PARAMS = "SET_ROOMS_REQUEST_PARAMS";
export const RESET_PARAMS = "RESET_PARAMS";
export const SET_BEST_OFFER = "SET_BEST_OFFER";
export const SET_PROMOTION = "SET_PROMOTION";
export const SET_EVENTS = "SET_EVENTS";
export const SET_SISTER_PROPERTIES = "SET_SISTER_PROPERTIES";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

const setPropertyLoading = (dispatch) => {
  return dispatch({ type: SET_PROPERTY_LOADING });
};

const setPropertyData = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_DATA, payload });
};

const setRoomsLoading = (dispatch) => {
  return dispatch({ type: SET_ROOMS_LOADING });
};

const setRoomsData = (dispatch, payload) => {
  return dispatch({ type: SET_ROOMS_DATA, payload });
};
const setPromotion = (dispatch, payload) => {
  return dispatch({ type: SET_PROMOTION, payload });
};
const setEvents = (dispatch, payload) => {
  return dispatch({ type: SET_EVENTS, payload });
};
const setNotifications = (dispatch, payload) => {
  return dispatch({ type: SET_NOTIFICATIONS, payload });
};
const setSisterProperties = (dispatch, payload) => {
  return dispatch({ type: SET_SISTER_PROPERTIES, payload });
};
const setFilterRoomsData = (dispatch, payload) => {
  return dispatch({ type: SET_FILTER_ROOMS_DATA, payload });
};

const setClosedDatesLoading = (dispatch) => {
  return dispatch({ type: SET_CLOSED_DATES_LOADING });
};

const setClosedDatesData = (dispatch, payload) => {
  return dispatch({ type: SET_CLOSED_DATES_DATA, payload });
};

const setParams = (dispatch, payload) => {
  return dispatch({ type: SET_PARAMS, payload });
};

const setRoomsRequestParams = (dispatch, payload) => {
  return dispatch({ type: SET_ROOMS_REQUEST_PARAMS, payload });
};

const resetParams = (dispatch) => {
  return dispatch({ type: RESET_PARAMS });
};

const setBestOffer = (dispatch, payload) => {
  return dispatch({ type: SET_BEST_OFFER, payload });
};

const loadBestOffer = async (dispatch, channelId, params) => {
  if (channelId === null) {
    setBestOffer(dispatch, null);
  } else {
    try {
      const newMinPriceParams = await ApiActions.getBestOffer(channelId, params);

      setBestOffer(dispatch, newMinPriceParams);
    } catch (_e) {
      setBestOffer(dispatch, null);
    }
  }
};

const loadProperty = async (dispatch, channelId) => {
  if (!channelId) {
    return {};
  }

  setPropertyLoading(dispatch);
  try {
    const data = await ApiActions.getPropertyInfo(channelId);

    setPropertyData(dispatch, data);

    return data;
  } catch (error) {
    setPropertyData(dispatch, null);

    if (error.status === 404) {
      throw Error("PROPERY_NOT_FOUND");
    }

    return {};
  }
};

const loadRoomsInfo = async (dispatch, channelId, params) => {
  if (!channelId) {
    return;
  }
  const checkInDate = moment(params.checkinDate);
  const currentDateTime = moment();
  const daysDifference = checkInDate.diff(currentDateTime, "days");
  setRoomsLoading(dispatch);

  setRoomsRequestParams(dispatch, params);
  const infants = params?.childrenAge?.filter((age) => age <= params?.maxInfantAge) || [];

  try {
    const data = await ApiActions.getRoomsInfo(channelId, params);
    let rooms = [...data];
    let finalRooms = [];

    const property = await ApiActions.getProperty(channelId);

    data.forEach((d, index) => {
      let filterArray = [];
      if (d?.ratePlans?.length === 1) {
        filterArray = d.ratePlans.filter((arr) => {
          return (
            arr?.occupancy?.adults === params?.adults &&
            arr?.occupancy?.children === params?.children - infants?.length &&
            arr?.occupancy?.infants === infants?.length
          );
        });
      } else {
        filterArray = d.ratePlans.filter((arr) => {
          const cancellationDeadline =
            arr.cancellationPolicy.cancellationPolicyDeadline &&
            daysDifference >= arr.cancellationPolicy.cancellationPolicyDeadline;

          return (
            arr?.occupancy?.adults === params?.adults &&
            arr?.occupancy?.children === params?.children - infants?.length &&
            arr?.occupancy?.infants === infants?.length &&
            (!property?.data[0]?.cancellation_policy_restriction ||
              !arr?.cancellationPolicy?.cancellationPolicyDeadline ||
              cancellationDeadline == true)
          );
        });
      }
      if (filterArray.length > 0) {
        rooms[index].ratePlans = filterArray;
        finalRooms.push(rooms[index]);
      }
    });

    setRoomsData(dispatch, finalRooms, params);
  } catch (error) {
    console.log("error", error);
    setRoomsData(dispatch, [], params);
  }
};

const loadFilterRoomsInfo = async (dispatch, channelId, params) => {
  if (!channelId) {
    return;
  }

  try {
    const data = await ApiActions.getRoomsInfo(channelId, params);

    setFilterRoomsData(dispatch, data, params);
  } catch (error) {
    setFilterRoomsData(dispatch, [], params);
  }
};

const loadClosedDates = async (dispatch, channelId) => {
  if (!channelId) {
    return;
  }

  setClosedDatesLoading(dispatch);

  try {
    const data = await ApiActions.getClosedDates(channelId);
    setClosedDatesData(dispatch, data);
  } catch (error) {
    setClosedDatesData(dispatch, null);
  }
};

const setParamsAndLoadRoomsInfo = (dispatch, channelId, bookingParams) => {
  setParams(dispatch, bookingParams);

  return loadRoomsInfo(dispatch, channelId, bookingParams);
};

const mergeBookingParams = (bookingQueryParams, savedBookingData) => {
  if (!savedBookingData || bookingQueryParams.channelId !== savedBookingData.channelId) {
    return bookingQueryParams;
  }

  const { params } = savedBookingData;

  return { ...params, ...bookingQueryParams };
};

const initBookingData = (dispatch, bookingQueryParams, savedBookingData) => {
  if (!bookingQueryParams.channelId) {
    throw Error("PROPERY_NOT_FOUND");
  }

  const bookingParams = mergeBookingParams(bookingQueryParams, savedBookingData);

  return loadProperty(dispatch, bookingQueryParams.channelId).then((property) => {
    const { currency: propertyCurrency = DEFAULT_CURRENCY, exactMatch = false } = property;
    const { currency = propertyCurrency } = bookingParams;
    const updatedParams = { ...bookingParams, currency, exactMatch };

    return setParamsAndLoadRoomsInfo(dispatch, bookingQueryParams.channelId, updatedParams);
  });
};
const getPromotions = async (dispatch, channelId) => {
  // const checkinDate = moment(params.checkinDate);
  // const checkoutDate = moment(params.checkoutDate);

  if (!channelId) {
    return;
  }

  try {
    const data = await ApiActions.getPromotion(channelId);
    if (data?.data) {
      const promotions = [...data.data];

      if (promotions?.length > 0) {
        setPromotion(dispatch, promotions);
      } else {
        setPromotion(dispatch, []);
      }
    }
  } catch (error) {
    setPromotion(dispatch, []);
    console.log("error", error);
  }
};
const getEvents = async (dispatch, channelId) => {
  if (!channelId) {
    return;
  }

  try {
    const data = await ApiActions.getEvents(channelId);

    if (data?.status === 200 && data?.data) {
      const events = [...data.data];

      if (events?.length > 0) {
        setEvents(dispatch, events);
      } else {
        setEvents(dispatch, []);
      }
    }
  } catch (error) {
    setEvents(dispatch, []);
    console.log("error", error);
  }
};
const getSisterProperties = async (dispatch, channelId) => {
  if (!channelId) {
    return;
  }

  try {
    const data = await ApiActions.getSisterProperties(channelId);

    if (data?.status === 200 && data?.data?.sister_channels) {
      const sisterProperties = [...data.data?.sister_channels];

      if (sisterProperties?.length > 0) {
        setSisterProperties(dispatch, sisterProperties);
      } else {
        setSisterProperties(dispatch, []);
      }
    }
  } catch (error) {
    setSisterProperties(dispatch, []);
    console.log("error", error);
  }
};
const getNotifications = async (dispatch, channelId) => {
  if (!channelId) {
    return;
  }

  try {
    const data = await ApiActions.getNotifications(channelId);

    if (data?.status === 200 && data?.data) {
      const notifications = [...data.data];

      if (notifications?.length > 0) {
        setNotifications(dispatch, notifications);
      } else {
        setNotifications(dispatch, []);
      }
    }
  } catch (error) {
    setNotifications(dispatch, []);
    console.log("error", error);
  }
};

export const actions = {
  loadProperty,
  loadRoomsInfo,
  loadFilterRoomsInfo,
  loadClosedDates,
  setParams,
  setPromotion,
  setEvents,
  setNotifications,
  setSisterProperties,
  resetParams,
  setParamsAndLoadRoomsInfo,
  initBookingData,
  getPromotions,
  loadBestOffer,
  getEvents,
  getNotifications,
  getSisterProperties,
};
